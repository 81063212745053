import React from 'react'
import fusesImg from './../images/fuses-diagram.png'
import styled from 'styled-components'
import imgOne from './../images/commercial-img-1.jpg'
import imgTwo from './../images/commercial-img-2.jpg'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

const StyledImage = styled( Image )`
  width: 90%;
`

const StyledP = styled.p`
  margin-top: 10px;
  width:90%;
  margin: 0 auto;
`

 const BkgdDiv = styled.div`
 width: 100%;
 padding: 100px;
 background: url(${fusesImg}) no-repeat center center fixed;
 `

 const StyledH1 = styled.h1`
 background: transparent;
   color: green;
   text-align: center;
   width: 90%;
   margin: 10px auto;
 `

 const StyledH2 = styled.h2`
 color: blue;
 text-align: center;
 width: 90%;
 margin: 10px auto;
`

const Commercial = () => {
   return (
     <div>
       <Jumbotron>
       <BkgdDiv>
       <StyledH1>{`Green Mountain Electric works on a full range of electrical systems in a commercial setting!`}</StyledH1>
       </BkgdDiv>
       </Jumbotron>
      
       <Jumbotron>
       <StyledH2>{`Contract us for a quality partner to help you at any stage of your systems installation, maintenance, upgrades and repair needs.`}</StyledH2>
      <StyledP className="text-center"><StyledImage rounded src={imgOne} /></StyledP>
      <StyledP className="text-center"><StyledImage rounded src={imgTwo} /></StyledP>

      <StyledP className="text-center">
      <Button variant="primary">
      <a href="tel:1-828-508-9764">{`Call Now!`}</a>
      </Button>
      </StyledP>
       </Jumbotron>
      </div>
   )
}

export default Commercial
