import React from 'react'
import aboutImg from './../images/about-image.jpg'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'

const StyledP = styled.p`
  margin-top: 10px;
  width:90%;
  padding-bottom: 15px;
  margin: 0 auto;
`

const StyledImg = styled(Image)`
width: 360px;
`
const About = () => {
   return (
      <div>
         <h1 className="text-center">{`About Us`}</h1>
         <StyledP>{`Green Mountain Electric was founded in 2019 by August Kotila.  August is a licensed electrician in North Carolina.`}</StyledP> 
         <StyledP>{`The company has it's roots in WNC and takes pride in doing the job right the first time. That means safely and professionially. August was raised in Cullowhee NC just a stones throw from Western Carolina University in the beautiful Smoky Mountains. He has an associates degree from Southwestern Community College in Webster NC.`}</StyledP><StyledP>{`Green Mountain Electric can take care of a wide range of services and is licensed for both commercial and residential work. For a job where you need some consulting we can arrange an on site visit so we can understand your needs and give you a free estimate.`}</StyledP>
         <StyledP className="text-center">
         <StyledImg 
            roundedCircle
            src={aboutImg} 
            alt="owner August Kotila with son and company truck" 
         />
         </StyledP>
      </div>
   )
}

export default About
