import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
    width: 100%;
    line-height: 25px;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`

const Footer = () => {
   return (
      <StyledFooter>
        <span class="text-muted">Email: augustckotila@gmail.com</span>
        <span class="text-muted">Phone: 828-508-9764</span>
        <span class="text-muted">Green Mountain Electric</span>
         <span>Servicing Western North Carolina</span>
    </StyledFooter>
   )
}

export default Footer
