import React from 'react'
import './styles/index.css';
import MainContent from './MainContent'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav';
import { Helmet } from 'react-helmet';
import Footer from './Footer'
import styled from 'styled-components'

const WrapperDiv = styled.div`
position:relative;
`

const seoPageConfig = {
  title: ``,
  description: ``
}

const HelmetSeoBlock = ( props ) => {
  if( window.location.pathname === `/residential-electric-service`){
    return (
       
              <Helmet>
      <title>Residential services - Green Mountain Electric</title>
      <meta name="description" content={`Green Mountain Electric of Western North Carolina offers a full range of residential electric services. We provide electrical installation and repair services, remodels, can layouts, new construction and much more. Contact August Kotila, owner, to find out more.`} />
  </Helmet>

    )
 }

 if( window.location.pathname === `/`){
  return (
     
            <Helmet>
    <title>Green Mountain Electric Home Page</title>
    <meta name="description" content={`Welcome to Green Mountain Electric's home page. We serve Western North Carolina and provide electrician's services to all of the region including Sylva, Cullowhee, Cherokee, Bryson, Murphy, Cashiers, Highlands, Waynesville, Asheville. Our specialties include commercial electric work and residential electric work.`} />
</Helmet>

  )
}

if( window.location.pathname === `/commercial-electric-service`){
  return (
     
            <Helmet>
    <title>Commercial Services - Green Mountain Electric </title>
    <meta name="description" content={`Green Mountain Electric serves the commercial building industry of Western North Carolina. We provide electrician's to help companies at any stage of their development. From new homes to maintenance of commercial buildings let our company help you please your stakeholders.`} />
</Helmet>

  )
}

if( window.location.pathname === `/electrician-western-north-carolina`){
  return (
     
            <Helmet>
    <title>About Us - Green Mountain Electric </title>
    <meta name="description" content={`Our company's founder August Kotila began taking clients in 2019. We are licensed North Carolina electricians. Call today for a free estimate.`} />
</Helmet>

  )
}

 return null
}

function App() {
  return (
    <div>
    <Container fluid>
    <div className="App">
      <HelmetSeoBlock />
      <header className="App-header">
      <Navbar bg="light" expand="lg">
  <Navbar.Brand href="/">Green Mountain Electric</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/residential-electric-service">Residential</Nav.Link>
      <Nav.Link href="/commercial-electric-service">Commercial</Nav.Link>
      <Nav.Link href="/electrician-western-north-carolina">About</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
</header>

        
        <div>
          <MainContent />
        </div>

    </div>
    </Container>
    <Footer />
    </div>
  );
}

export default App;
