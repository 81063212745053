import React from 'react'
import { Jumbotron } from 'react-bootstrap'
import canImage from './../images/work-example.jpg'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'

const FlexDiv = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
margin: 25px 0 0 0;
`

const StyledDiv = styled.div`
margin: 10px;
`
const Residential = () => {
   return (
      <div>
      <Jumbotron>
         <FlexDiv>
         <h1>{`Residential Electrical Services`}</h1>
         <p>{`Green Mountain Electric offers full service electrical installation, maintenance and repair. Call today to see how we can help!`}</p>
         <StyledDiv>
            <Image rounded src={canImage} />
         </StyledDiv>
         </FlexDiv>
         </Jumbotron>

         <Jumbotron>
            <FlexDiv>
               <h2>{`Our crew is led by a North Carolina licenced, certified and insured electrician. Call today if you need services for any of the following:`}</h2>
         <ListGroup>
            <ListGroup.Item>{`Electrical installation and repair service`}</ListGroup.Item>
            <ListGroup.Item>{`Can layouts`}</ListGroup.Item>
            <ListGroup.Item>{`LED retrofits and upgrades`}</ListGroup.Item>
            <ListGroup.Item>{`Services, new and upgrades`}</ListGroup.Item>
            <ListGroup.Item>{`Panel boxes`}</ListGroup.Item>
            <ListGroup.Item>{`Fuse boxes`}</ListGroup.Item>
            <ListGroup.Item>{`New construction`}</ListGroup.Item>
            <ListGroup.Item>{`Remodels`}</ListGroup.Item>
            <ListGroup.Item>{`Dedicated curcuits`}</ListGroup.Item>
            <ListGroup.Item>{`Ceiling fan installation`}</ListGroup.Item>
            <ListGroup.Item>{`Cans`}</ListGroup.Item>
            <ListGroup.Item>{`Switches`}</ListGroup.Item>
            <ListGroup.Item>{`Plugs`}</ListGroup.Item>
            <ListGroup.Item>{`Storm damage`}</ListGroup.Item>
         </ListGroup>
         <p>We also work on many other types of electrical work.</p>
         <p className="text-center">
    <Button variant="primary">
      <a href="tel:1-828-508-9764">{`Call Now!`}</a>
      </Button>
    </p>
         </FlexDiv>
         </Jumbotron>
      </div>
   )
}

export default Residential
