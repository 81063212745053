import React from 'react'
import Home from './pages/Home'
import Commercial from './pages/Commercial'
import Residential from './pages/Residential'
import About from './pages/About'
import { Helmet } from 'react-helmet'

export const MainContent = () => {


      if( window.location.pathname === `/`){
         return (
            <Home />
         )
      }
      if( window.location.pathname === `/commercial-electric-service`){
         return (
            <Commercial />
         )
      }
      if( window.location.pathname === `/residential-electric-service`){
         return (
            <Residential />
         )
      }
      
      if( window.location.pathname === `/electrician-western-north-carolina`){
         return (
            <About />
         )
      }
      <>
      <Home />
      <Residential />
      </>
}

export default MainContent
