import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Button from 'react-bootstrap/Button'
import logo from './../images/logo.jpg'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'

const FlexDiv = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
margin: 25px 0 0 0;
`

const StyledTopLogoContainer = styled.div`
width: 100%;
background-color: #ececec;
display: flex;
flex-direction: row;
justify-content:center;
padding: 25px;
`

const StyledImage = styled(Image)`
  max-width: 100%;
`

const Home = ( props) => {
   return (
      <>
<StyledTopLogoContainer>

<StyledImage src={logo} className="App-logo" alt="logo" />
</StyledTopLogoContainer>

<Jumbotron>
  <h1>Electricians providing full service commercial and electrical installation, maintenance and repair for Western North Carolina.</h1>
  <p>We provide top quality electrical work for a wide range of services including home improvements, remodels, new construction and commercial projects.</p>
  <p>Our goal is to make sure you're electrical work is safe, properly installed and just the way you want it!</p>
  <p>Call today to see how Green Mountain Electric can help you! We offer free estimates and on-site evaluations.</p>
  <p className="text-center"> <Button variant="primary"><a href="tel:1-828-508-9764">{`Call now!`}</a></Button></p> 
</Jumbotron>

<h3>{`NCL# 33218`}</h3>

  <Jumbotron>
  <FlexDiv>
    <h2>We are proud of our Western North Carolina roots and strive to help our community by providing honest electrical work. We pride ourselves on safe and sound electrical installation, upgrades and maintenance. Not only that but we can help bring all your remodel and renovation dreams to life with fantastic lighting!</h2>
    
    <p>Green Mountain Electric services all of WNC including</p>
    <ul>
      <li>
        Sylva and Jackson County
      </li>
      <li>
        Waynesville and Haywood County
      </li>
      <li>
        Bryson City and Swain County
      </li>
      <li>Cherokee</li>
      <li>Cashiers and Highlands</li>
      <li>Franklin</li>
      <li>Asheville and Buncombe County</li>
      <li>Cullowhee</li>
    </ul>
    <p className="text-center">
    <Button variant="primary">
      <a href="tel:1-828-508-9764">{`Call Now!`}</a>
      </Button>
    </p>
    </FlexDiv>
  </Jumbotron>
  </>
   )
}

export default Home
